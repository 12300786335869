import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import { CircularProgress } from '@mui/material'
import Table from 'components/main/Table/Table'
import MapView from './mapView/mapView'
import { pathOr } from 'ramda'
import { columnConfig } from 'constants/columns'
import {
  selectedRecordsAtom,
  dataFetchLoadingAtom,
  globalPostAtom,
  selectedFiltersAtom,
  actionCodeAtom,
  rowDataAtom,
  businessDaysAtom,
  statesAtom,
  personnelDataAtom,
  mapViewAtom,
  resolveIssueAtom,
  skipFilterAtom,
  selectedRowAtom,
  searchTermAtom,
  sortByAtom
} from 'constants/atoms'
import { ACTION_CODES } from 'constants/actionCodes'
import { appNavigatorItemsConst } from 'constants/sidebarItems'
import FetchData, { getBusinessDays, getStates } from 'services/fetchData'
import { getPersonnel } from '../login/loginActions'
import { filterConfig } from 'utils/filterConfig'
import { getItem } from 'utils/localstorage'

const Viewpages = ({
  selectedView,
  selectedSubView,
  setSelectedView,
  setSelectedSubView,
}) => {
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom)
  const [mapView] = useAtom(mapViewAtom)
  const { data } = FetchData(selectedView, selectedSubView, selectedFilters)
  const [, setSortBy] = useAtom(sortByAtom)
  const [, setSelectedRecords] = useAtom(selectedRecordsAtom)
  const [, setSelectedRows] = useAtom(selectedRowAtom)
  const [, setSearchAtom] = useAtom(searchTermAtom)
  const [dataFetchLoading] = useAtom(dataFetchLoadingAtom)
  const [globalLoading] = useAtom(globalPostAtom)
  //const [, setBusinessDays] = useAtom(businessDaysAtom)
  const [, setActionCode] = useAtom(actionCodeAtom)
  const [, setRowData] = useAtom(rowDataAtom)
  const [, setStates] = useAtom(statesAtom)
  const [, setPersonnelData] = useAtom(personnelDataAtom)
  const [, setResolveIssue] = useAtom(resolveIssueAtom)
  const [, setSkipFilter] = useAtom(skipFilterAtom)
  const userData = getItem('userData')
  const vendorData = getItem('vendor')
  const selectedVendor = vendorData.find(
    ({ vendor_id: id }) => userData?.vendor_id === id,
  )
  const country = pathOr('USA', ['country_code'], selectedVendor)

  const showCheckbox = ['lotsView', 'tripsView'].includes(selectedView)
  const displayMap = selectedView === 'lotsView' || selectedView === 'tripsView'

  useEffect(() => {
    if (selectedView === 'tripsView') {
      setSelectedRows([])
      setSelectedRecords(0)
    }
  }, [mapView])

  useEffect(() => {
    //getBusinessDays().then((resp) => setBusinessDays(resp))
    getStates(country).then((resp) => setStates(resp))
  }, [setStates])

  useEffect(() => {
    if (selectedView === 'truckManagementView') {
      getPersonnel().then((personnelData) => setPersonnelData(personnelData))
    }
    setSortBy({})
  }, [selectedView, setPersonnelData])

  const handleAssignmentClick = (data) => {
    if (
      ['reportedDeclinedTrips', 'reportedAssignedTrips'].includes(
        selectedSubView,
      )
    ) {
      setSelectedFilters({
        [appNavigatorItemsConst.LOTS]: {
          ...filterConfig[selectedSubView],
          current_trip_id: [data.trip_id],
        },
      })
    } else
      setSelectedFilters({
        [appNavigatorItemsConst.LOTS]: {
          current_trip_id: [data.trip_id],
        },
      })
    setRowData([])
    setSearchAtom('')
    setSelectedView(appNavigatorItemsConst.LOTS)
    setSelectedSubView(null)
    setSkipFilter(true)
  }

  const handleActionClick = (data, action) => {
    setRowData(data)

    switch (selectedView) {
      case 'driversView':
        if (action === 'requestPcard') {
          setActionCode(ACTION_CODES.REQUEST_PCARD)
        }
        if (action === 'deletePcard') {
          setActionCode(ACTION_CODES.DELETE_PCARD)
        }
        if (action === 'editDriver') {
          setActionCode(ACTION_CODES.ADD_DRIVER)
        }
        break
      case 'workRequestsView':
        setActionCode(ACTION_CODES.DELETE_REQUEST)
        break
      case 'catTowProvidersView':
        setActionCode(ACTION_CODES.MANAGE_DRIVER)
        break
      case 'truckManagementView':
        setActionCode(ACTION_CODES.ADD_TRUCK)
        break
      case 'lotsView':
        if (action === 'createIssue') {
          setActionCode(ACTION_CODES.CREATE_ISSUE)
        }
        if (action === 'distributeTrip') {
          setActionCode(ACTION_CODES.DISTRIBUTE_TRIP)
        }
        if (action === 'cancel') {
          setActionCode(ACTION_CODES.CAN_PICKUP)
        }
        if (action === 'issueResolution') {
          setActionCode(ACTION_CODES.RESOLVE_ISSUE)
          setResolveIssue(true)
        }
        if (action === 'markIssueAsRead') {
          setActionCode(ACTION_CODES.MARK_ISSUE_AS_READ)
        }
        if (action === 'viewLot') {
          setActionCode(ACTION_CODES.VIEW_LOT)
        }
        break
      case 'tripsView':
        if (action === 'overrideDeclineTrip') {
          setActionCode(ACTION_CODES.OVERRIDE_DECLINE)
        }
        if (action === 'removeTransporter') {
          setActionCode(ACTION_CODES.REMOVE_DRIVER)
        }
        if (action === 'cancelDeclineTrip') {
          setActionCode(ACTION_CODES.TAKE_BACK_AND_ACCEPT)
        }
        if (action === 'distributeTrip') {
          setActionCode(ACTION_CODES.DISTRIBUTE_TRIP)
        }
        if (action === 'acknowledgeTrip') {
          setActionCode(ACTION_CODES.ACKNOWLEDGE_TRIP)
        }
        if (action === 'createIssue') {
          setActionCode(ACTION_CODES.CREATE_ISSUE)
        }
        if (action === 'assignments') {
          handleAssignmentClick(data)
        }
        if (action === 'cancel') {
          setActionCode(ACTION_CODES.CAN_PICKUP)
        }
        if (action === 'issueResolution') {
          setActionCode(ACTION_CODES.RESOLVE_ISSUE)
          setResolveIssue(true)
        }
        break
      default:
        break
    }
  }

  const openViewLotDialog = (data) => {
    setRowData(data)
    setActionCode(ACTION_CODES.VIEW_LOT)
  }

  const renderRows = () => {
    if (dataFetchLoading || globalLoading) return <CircularProgress />
    return data.map((item, index) => ({
      id:
        selectedView === 'tripsView'
          ? item.trip_id
          : item.dispatch_assignment_detail_id,
      ...item,
    }))
  }

  return (
    <>
      {mapView && displayMap ? (
        <MapView handleActionClick={handleActionClick} />
      ) : dataFetchLoading || globalLoading ? (
        <CircularProgress />
      ) : (
        <Table
          columns={
            columnConfig(handleActionClick, openViewLotDialog)[
              selectedSubView ? selectedSubView : selectedView
            ]
          }
          rows={renderRows()}
          onRowSelectionChange={(newSelected) => {
            setSelectedRecords(newSelected.length)
          }}
          showCheckbox={showCheckbox}
          onActionClick={handleActionClick}
        />
      )}
    </>
  )
}

export default Viewpages
