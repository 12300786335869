import React, { useState, useRef } from 'react'
import { IconButton, InputAdornment, Popover, TextField } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { DateRange } from 'react-date-range'
import { useAtom } from 'jotai'
import { tripFromDateAtom, tripToDateAtom } from 'constants/atoms'
import { enUS } from 'date-fns/locale'
import dayjs from 'dayjs'
import { getDateRange } from '../../../utils/date'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const TripDateRange = () => {
  const [tripFromDate, setTripFromDate] = useAtom(tripFromDateAtom)
  const [tripToDate, setTripToDate] = useAtom(tripToDateAtom)

  const [properties, setProperties] = useState({ open: false, anchorEl: null })
  const textFieldRef = useRef(null)

  const today = dayjs()
  const oneYearAgo = today.subtract(1, 'year')
  const defaultDateRange = [
    {
      startDate: tripFromDate ? dayjs(tripFromDate).toDate() : null,
      endDate: tripToDate ? dayjs(tripToDate).toDate() : null,
      key: 'selection',
    },
  ]

  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection
    setTripFromDate(startDate ? startDate.toISOString().split('T')[0] : '')
    setTripToDate(endDate ? endDate.toISOString().split('T')[0] : '')
  }

  const handleOnClick = (e) => {
    e.preventDefault()
    setProperties({
      anchorEl: textFieldRef.current,
      open: !properties.open,
    })
  }

  const handleOnRequestClose = () => {
    setProperties({ open: false, anchorEl: null })
  }

  return (
    <>
      <TextField
        ref={textFieldRef}
        label="Trip Dates"
        variant="outlined"
        fullWidth
        value={getDateRange(defaultDateRange)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Date range picker"
                onClick={handleOnClick}
                sx={{ padding: '0' }}
              >
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ width: '222px' }}
      />
      <Popover
        open={properties.open}
        anchorEl={properties.anchorEl}
        onClose={handleOnRequestClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRange
          locale={enUS}
          editableDateInputs={true}
          ranges={defaultDateRange}
          moveRangeOnFirstSelection={false}
          getDateRange={false}
          onChange={handleDateChange}
          staticRanges={[]}
          inputRanges={[]}
          minDate={oneYearAgo.toDate()}
          maxDate={today.toDate()}
        />
      </Popover>
    </>
  )
}

export default TripDateRange
